@import '../../../styles/lib';

.SelectBox {
  &--open {
    .SelectBox__icon {
      path {
        fill: #fd9f90;
      }
    }
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__header {
    @include rem(font-size, 14px);
    font-weight: 600;
    color: black;
    @include rem(margin-right, 5px);

    @include tablet {
      font-size: 12px;
    }
  }

  &__customer {
    background-color: #71e200;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  &__icon {
    width: 10px;
    height: 6px;
  }

  &__menu {
    position: absolute;
    @include rem(top, 10px);
    @include rem(left, 60px);
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.16);
    z-index: 99;
    background: #ffffff;
    @include rem(border-radius, 10px);
    border: 0.5px solid #ccd9e2;
    margin-top: 10px;
    @include rem(width, 251px);
    @include rem(margin-top, 10px);
    transition-property: transform, opacity;
    transition-duration: 400ms;
    transition-timing-function: cubic-bezier(0.4, 0.2, 0, 1.1);
    will-change: transform;
    opacity: 0;
    visibility: hidden;
    @include tablet {
      width: 30%;
      @include rem(top, 0px);
      @include rem(left, 10px);
    }
    @include mobile {
      width: 60%;
    }
  }

  &__error {
    color: $red;
  }
  &__search {
    border-bottom: 1px solid #ccd9e2;
    display: flex;
    align-items: center;
    @include rem(padding, 19px);
    @include tablet {
      padding: 10px;
    }
  }

  &__input {
    display: flex;
    align-items: center;
    .Input__field {
      @include rem(height, 34px);
      background-color: white;
      @include tablet {
        height: 34px;
      }
    }
  }
  &__add_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    cursor: pointer;
  }

  &__menu--open {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  &__items {
    margin-bottom: 0;
    @include rem(max-height, 435px);
    overflow-y: auto;
    @include rem(margin-top, 30px);
    @include rem(margin-right, 12px);
    padding: 0;
    li {
      @include rem(height, 32px);
      @include rem(margin-right, 28px);

      @include rem(margin-bottom, 18px);
      @include rem(padding-left, 26px);
      @include rem(line-height, 21px);
      display: flex;
      align-items: center;
      cursor: pointer;
      @include tablet {
        height: 30px;
      }
      &:hover {
        background-color: rgba(221, 201, 192, 0.25);
      }

      @include tablet {
        padding: 10px;
      }
    }
    @include tablet {
      max-height: 400px;
    }
  }
  &__item--active {
    background-color: rgba(221, 201, 192, 0.25);
    &:hover {
      background-color: rgba(221, 201, 192, 0.25) !important;
    }
  }

  &__selected {
    width: 11px;
    height: 9px;
    margin-left: auto;
    @include rem(margin-right, 6px);
  }
}
