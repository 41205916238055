@import '../../../styles/lib';

.TableWrapper {
  &__content {
    @include rem(padding, 55px 79px 0px 27px);
    @include tablet {
      @include rem(padding, 55px 27px 0px);
    }
    width: 100%;
    height: 100%;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    margin: 0;
    @include rem(font-size, 24px);
    font-weight: 600;
    color: #4e4e4e;
    line-height: 35px;
    @include rem(margin-left, 15px);
  }

  &__add {
    display: flex;
    @include rem(margin-right, 57px);

    align-items: center;
    justify-content: center;
  }
}
