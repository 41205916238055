@import '../../../styles/lib';

.NotificationForm {
  &__input {
    display: flex;
    align-items: center;
    label {
      margin-right: 5px;
    }
    margin-bottom: 15px;
    // .Input__wrapper {
    //   @include tablet {
    //     width: 100%;
    //   }
    // }
    .Input__field {
      @include rem(width, 270px);
      max-height: 150px;
      @include tablet {
        width: 100%;
        width: 280px;
      }
      @include mobile {
        width: 100%;
        width: 195px;
      }
    }
  }

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $primary;
    color: white;
    font-size: 14px;
    @include rem(width, 270px);
    @include rem(height, 38px);
    @include rem(padding, 9px 16px);
    text-align: start;
    border-radius: 4px;
    margin-left: auto;
    @include tablet {
      width: 66%;
      height: 38px;
    }
    @include mobile {
      width: 69%;
      height: 38px;
    }
    &:hover {
      background-color: white;
      color: black;
    }
  }

  &__arrow {
    width: 11px;
    height: 8px;
    transform: rotate(-90deg);
  }
}
