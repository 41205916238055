@import '../../../styles/lib';

.LoginForm {
  width: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: 32px;
    color: white;
    width: fit-content;
    margin-bottom: 31px;
  }
  &__form_wrapper {
    width: 100%;
  }

  &__input {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .Input__field {
      color: #000;
      font-size: 24px;
      @include rem(padding, 14px);
      @include tablet {
        height: fit-content;
      }
    }
  }

  &__button {
    width: 100%;
    border-radius: 5px;
    background: transparent;
    border: 1px solid #ffffff;
    height: 60px;
    font-size: 24px;
    color: #ffffff;
    outline: none;
    font-weight: 600;

    &:hover {
      background-color: #fff;
      transition: all 0.3s;
      color: #000;
    }

    &:focus {
      outline: none;
    }
  }

  @include tablet {
    width: 80%;
  }
  &__forgot-password {
    color: white;
    cursor: pointer;
    width: 100px;
  }
}
