@import '../../styles/lib';

.ReportGenerator {
  width: 100%;

  @include mobile {
    // background-color: red;
  }
  @include tablet {
    // background-color: blue;
    @include mobile {
      // background-color: red;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    @include rem(margin, 48px 100px);

    @include tablet {
      display: flex;
      flex-direction: column;
      width: auto;
      @include mobile {
        // width: 60%;
      }
    }
  }

  label {
    @include rem(margin-bottom, 21px);
    @include tablet {
      margin-bottom: 0px;
    }
  }
  &__date {
    @include rem(width, 232px);
    @include rem(margin-right, 29px);
    @include tablet {
      margin-right: 0px;
    }
    @include rem(height, 38px);
    position: relative;
    border: 1px solid $primary;
    border-radius: 4px;

    .DatePicker {
      width: 100%;
    }
    .DatePicker__field {
      text-align: start;
      @include rem(padding, 7px 10px);
      height: 100%;
      width: 100%;
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        @include rem(font-size, 14px);
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        @include rem(font-size, 14px);
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        @include rem(font-size, 14px);
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        @include rem(font-size, 14px);
      }
    }
  }

  &__select {
    @include rem(width, 232px);
  }

  &__select_wrapper {
    @include rem(margin-right, 29px);
    @include tablet {
      margin-right: 0px;
    }
  }

  &__button {
    background-color: $primary;
    color: white;
    @include rem(font-size, 16px);
    @include rem(width, 232px);
    @include rem(height, 38px);
    @include rem(border-radius, 4px);
    @include rem(margin-top, 26px);
    &:hover {
      background-color: white;
      color: black;
    }
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__status {
    display: flex;
    flex-direction: column;
    @include tablet {
      display: block;
      @include mobile {
        // display: inline-grid;
      }
    }
  }

  &__error {
    @include rem(width, 300px);
    margin: auto;
  }

  &__description {
    @include tablet {
      display: flex;
      // background-color: red;
      justify-content: space-evenly;
      align-items: center;
      @include rem(margin-bottom, 15px);
      @include mobile {
        display: inline-block;
      }
    }
  }
  &__button-content {
    @include tablet {
      display: flex;
      justify-content: center;
      @include mobile {
        justify-content: start;
      }
    }
  }
}
