@import '../../../../styles/lib';

.Event {
  padding: 0;
  color: black;
  @include tablet {
    font-size: 12px;
  }
  .rbc-event-label {
    display: none;
  }

  &__popover {
    top: -1rem !important;
    left: -0.8rem !important;

    @include tablet {
      left: 0 !important;
    }
    .arrow {
      width: 15px !important;
      height: 15px !important;
      border-radius: 0;
      left: 98.2% !important;
      top: 1.7rem;
      border-top: 1px solid $primary !important;
      border-right: 1px solid $primary !important;
      border-radius: 0 !important;
      z-index: -1;
    }
  }
  &__right {
    top: -1rem !important;
    left: 0.8rem !important;
    @include tablet {
      left: 0 !important;
    }
    .arrow {
      width: 15px !important;
      height: 15px !important;
      left: -2% !important;
      top: 1.7rem;
      border-radius: 1px !important;
      border-top: 0 !important;
      border-right: 0 !important;
      border-bottom: 1px solid $primary !important;
      border-left: 1px solid $primary !important;
    }
  }

  &__title {
    @include rem(margin-top, 5px);
    line-height: 21px;
  }
  span {
    font-weight: bold;
  }

  &__customer {
    @include rem(line-height, 20px);
    @include tablet {
      line-height: 15px;
    }
  }
  &__button {
    @include rem(padding, 5px 10px);
    width: 100%;
    height: 100%;
    border: none;
    background-color: rgb(255, 230, 209);
    text-align: start;
    display: flex;
    flex-direction: column;

    span {
      @include rem(line-height, 20px);
    }
    &:active {
      color: black;
    }
  }
  &__disabled {
    cursor: default !important;
    &::after {
      content: 'CLOSED';
      color: white;
      margin: auto;
      @include rem(font-size, 14px);
      @include rem(line-height, 21px);
    }
    .Event__date {
      display: none;
    }
  }

  &__break {
    background: repeating-linear-gradient(
      135deg,
      rgba(0, 0, 0, 0.05),
      rgba(0, 0, 0, 0.05) 10px,
      rgba(128, 128, 128, 0.4) 10px,
      rgba(128, 128, 128, 0.4) 20px
    );
  }

  &__noShow {
    .Event__date {
      display: none;
    }
  }

  &__date {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include rem(line-height, 17px);
    @include rem(font-size, 12px);
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
    @include tablet {
      display: none;
    }
    div {
      color: #585858;
      font-weight: 400;
    }
  }
}
