@import '../../../styles/lib';

.AssignServiceToStaffForm {
  @include rem(width, 253px);

  &__select {
    @include rem(width, 253px);
  }

  &__submit {
    @include rem(padding, 10px 15px);
  }
}
