@import '../../../styles/lib';

.Popover {
  @include rem(max-width, 400px);
  box-shadow: 0 3px 6px $primary;
  @include rem(padding, 24px 33px);
  @include rem(border-radius, 23px);
  border: 1px solid $primary;
  @include mobile {
    max-width: 95%;
  }

  &__header {
    @include rem(font-size, 18px);
    @include rem(line-height, 25px);
    padding: 0;
    @include rem(padding-bottom, 14px);
    border-bottom: 1px solid $primary;
    background-color: white;
  }

  .arrow {
    content: '';
    position: absolute;
    @include rem(width, 35px);
    @include rem(height, 35px);
    border-bottom: 0;
    border-right: 0;
    border-radius: 5px;
    bottom: 100%;
    left: 8%;
    right: 95%;
    z-index: -5;
    transform: rotate(45deg);
    background-color: white;
    @include rem(margin-bottom, -32px);
    @include tablet {
      margin-left: -95px;
    }
    @include mobile {
      margin-left: -95px;
    }
  }
  &__body {
    padding: 0;
  }
}
