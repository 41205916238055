@import '../../styles/lib';

.Staff {
  height: 100%;
  display: flex;
  width: 100%;
  @include tablet {
    display: block;
  }
  &__navigation {
    width: 100%;
    @include rem(margin, 57px 79px 53px 26px);
    @include tablet {
      width: 95%;
    }
  }
  &__route {
    @include rem(margin-top, 52px);
  }
}
