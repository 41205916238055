@import '../../../../styles/lib';

.NavSidebarButton {
  border: none;
  @include rem(width, 36px);
  @include rem(height, 36px);
  margin-left: auto;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  @include rem(margin-bottom, 65px);
}
