@import '../../styles/lib';

.StaffPagination {
  display: flex;
  align-items: center;
  @include rem(margin-left, 124px);

  @include tablet {
    padding-right: 15px;
    margin: 0 auto;
  }
  &__button {
    background-color: transparent;
    border: none;
    &:disabled {
      svg {
        path {
          fill: $primary;
        }
      }
    }
  }
  &__previous {
    transform: rotate(90deg);
    width: 10px;
    height: 6px;
    color: black;
    @include rem(margin-right, 19px);
    &:hover {
      opacity: 0.3;
      cursor: pointer;
    }
  }

  &__next {
    color: black;
    width: 10px;
    height: 6px;
    transform: rotate(270deg);
    @include rem(margin-left, 19px);

    &:hover {
      opacity: 0.3;
      cursor: pointer;
    }
  }
}
