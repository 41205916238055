@import '../../styles/lib';

.AddAppointment {
  &__form {
    width: 100%;
    @include rem(padding, 44px 0 0);
    display: flex;
    flex-direction: column;
    @include tablet {
      padding: 30px 0 0;
    }
  }

  &__tab {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    align-items: center;
    .Select__wrapper {
      margin: 0;
      height: 20px;
    }
    .Select__placeholder {
      width: 25% !important;
    }
  }

  .Select__placeholder {
    width: 76%;
  }
}
