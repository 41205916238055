@import '../../../styles/lib';

.StaffNavbar {
  @include rem(height, 26px);
  width: 100%;

  &__list {
    display: flex;
    padding: 0;
    border-bottom: 1px solid #d1beb6;
    @include rem(padding-bottom, 5.5px);
  }

  &__item {
    text-decoration: none;
    @include rem(margin-right, 54px);
    @include rem(padding-bottom, 5px);
    @include small-desktop {
      @include rem(margin-right, 25px);
    }
    @include tablet {
      @include rem(margin-right, 44px);
    }
  }

  &__item_active {
    color: black;
    border-bottom: 2px solid #404040;
  }
}
