@import '../../../styles/lib';

.Input {
  display: flex;
  &__field {
    border-radius: 5px;
  }
  &__error {
    @include rem(font-size, 14px);
    // margin-top: 5px;
    @include rem(margin-left, 8px);
  }

  &__field {
    width: 100%;
    border: 1px solid $primary;
    @include rem(padding-left, 16px);
    @include tablet {
      font-size: 16px;
      height: 38px;
    }
    &:focus {
      background-color: white;
      box-shadow: none;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black !important;
  }
}
