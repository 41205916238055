@import './styles/lib';

.App {
  width: 100%;

  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;

  height: auto;
}
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar:horizontal {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}
