@import 'src/styles/lib';

.Login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url('../../assets/images/background-login.png');
  background-position: bottom;
  width: 100%;
  height: 100vh;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  &__title {
    color: #fff;
    font-weight: 600;
    font-size: 32px;
    font-family: $font-family-base;
    @include rem(margin-bottom, 31px);
  }
}
