@import '../../../styles/lib';
@keyframes bellshake {
  0% {
    transform: rotate(0);
  }
  15% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(-5deg);
  }
  45% {
    transform: rotate(4deg);
  }
  60% {
    transform: rotate(-4deg);
  }
  75% {
    transform: rotate(2deg);
  }
  85% {
    transform: rotate(-2deg);
  }
  92% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0);
  }
}
.Header {
  grid-area: header;
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: white;
  @include rem(height, 97px);
  @include rem(padding, 0 70px 0 144px);

  @include tablet {
    padding: 0;
  }

  @include tablet {
    .Header__dropdown {
      position: unset !important;
    }
  }
  .Dropdown__title {
    display: none;
  }
  &__notification {
    margin-left: 14px;
    cursor: pointer !important;
  }
  &__menu {
    @include rem(width, 200px);
    top: -2.5rem !important;
    padding: 0;
    @include rem(border-radius, 10px);
    background-color: transparent;
    border: none;
    left: 0.625rem !important;

    &::after {
      display: none;
    }

    @include tablet {
      top: -4% !important;
      left: -10% !important;
      border-radius: 10px;
    }
    @include mobile {
      top: -5% !important;
      left: -13% !important;
    }
  }
  .dropdown-divider {
    margin: 0;
  }
  &__toggle_account {
    display: flex;
    align-items: center;
    color: white;
    @include rem(font-size, 14px);
    &:hover {
      transition: all 0.3s;
      color: $secondary;
    }
    &:active {
      box-shadow: none;
    }
    @include mobile {
      font-size: 18px;
    }
    @include tablet {
      font-size: 16px;
    }
  }

  &__toggle_icon {
    @include rem(width, 10px);
    @include rem(height, 6px);
    margin-left: 6px;
    // @include tablet {
    //   width: 10px;
    //   height: 6px;
    // }
  }

  &__icon {
    @include rem(width, 194px);
    @include rem(height, 194px);
    // @include tablet {
    //   width: 20%;
    //   @include rem(margin-bottom, 300px);
    // }
    // @include mobile {
    //   width: 30%;
    //   @include rem(margin-bottom, 500px);
    // }
    &--notification {
      @include rem(width, 21px);
      @include rem(height, 21px);
      &:hover,
      &:focus {
        animation: bellshake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        backface-visibility: hidden;
        transform-origin: top right;
      }
    }

    @include tablet {
      width: 120px;
      height: 120px;
    }
  }

  &__navigation_wrapper {
    display: flex;
    align-items: center;
  }

  li {
    @include rem(margin-left, 42px);
    @include rem(line-height, 21px);
  }

  &__navigation_item {
    color: white;
    text-decoration: none;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    &:hover {
      color: $secondary;
    }
    @include tablet {
      font-size: 16px;
    }
    // @include tablet {
    //   @include rem(font-size, 60px);
    // }
  }

  &__navigation {
    display: flex;
    margin-bottom: 0;
    align-items: center;
  }

  &__avatar {
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    color: black;
    @include rem(width, 42px);
    @include rem(height, 42px);
    @include rem(border-radius, 100px);
    @include rem(margin-left, 32px);
    // @include tablet {
    //   @include rem(width, 120px);
    //   @include rem(height, 120px);
    //   font-size: 18px;
    // }
    @include mobile {
      margin-left: 8px;
    }
  }
}
