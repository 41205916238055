@import '../../styles/lib';

.ServicesWithDetailSelect {
  &__option {
    cursor: pointer;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &:hover {
      background-color: rgba(221, 201, 192, 0.5);
    }
    @include tablet {
      padding: 10px 10px !important;
    }
  }
  &__label {
    overflow: hidden;
    width: fit-content;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 70%;
    @include tablet {
      font-size: 12px;
      min-height: 18px;
      line-height: 21px;
      height: fit-content;
    }
  }
  &__color {
    @include rem(min-width, 10px);
    @include rem(height, 10px);
    @include rem(margin-right, 8px);
    @include rem(border-radius, 3px);
  }

  &__time {
    @include rem(min-width, 57px);
    @include rem(margin-left, 8px);
    overflow: hidden;
    white-space: nowrap;
    @include tablet {
      font-size: 12px;
      overflow: hidden;
      width: fit-content;
      white-space: nowrap;
      min-height: 18px;
      line-height: 21px;
    }
  }
}
