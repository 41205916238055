@import '../../../styles/lib';

.CustomersSidebar {
  .SidebarList {
    @include tablet {
      @include rem(max-height, 350px);
    }
  }
  &__dropdown {
    display: flex;
    justify-content: flex-end;
    z-index: 2;
  }
  &__menu {
    padding: 0;
  }
  &__view {
    @include rem(font-size, 18px);
    border-bottom: none;
    color: $primary;
    z-index: -1;
    &:hover {
      color: black;
      border-bottom: none;
    }
  }
}
