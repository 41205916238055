@import '../../../../styles/lib';

.TimeOff {
  @include rem(margin-left, 15px);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include rem(margin-right, 63px);
  }

  &__table {
    @include rem(margin-top, 20px);
    tr {
      cursor: pointer;

      &:hover {
        border-bottom: 1px solid black;
        td {
          color: black;
        }
        .TimeOff__delete {
          display: block;
        }
      }
    }
  }

  &__delete {
    display: none;
    @include rem(width, 15px);
    @include rem(height, 17px);
    filter: invert(32%) sepia(82%) saturate(7118%) hue-rotate(353deg) brightness(107%) contrast(137%);
  }

  &__date {
    color: black;
    @include rem(font-size, 16px);
  }

  &__description {
    color: grey;
    @include rem(font-size, 14px);
  }

  &__placeholder {
    @include rem(margin-top, 20px);
  }

  &__skeleton {
    @include rem(margin, 20px 0);
    @include rem(height, 25px);
  }
}
