@import '../../../styles/lib';

.AddNewButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: $green;
  border: 1px solid $green;
  border-radius: 50%;
  @include rem(width, 28px);
  @include rem(height, 28px);

  @include tablet {
    width: 28px;
    height: 28px;
  }
  &:hover {
    background-color: white;
    border: none;
    border: 1px solid $green;
  }
  &:focus {
    background-color: white;
    border: 1px solid $green;
    outline: none !important;
    svg {
      filter: invert(59%) sepia(100%) saturate(408%) hue-rotate(45deg) brightness(100%) contrast(113%);
    }
  }
  &:active {
    background-color: white;
    box-shadow: none;
    outline: none !important;
    border: none;
  }
  &__icon {
    @include rem(width, 27px);
    @include rem(height, 27px);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      @include rem(width, 11px);
      @include rem(height, 11px);
    }

    &:hover {
      filter: invert(59%) sepia(100%) saturate(408%) hue-rotate(45deg) brightness(100%) contrast(113%);
    }
  }
}
