@import '../../../styles/lib';

.AddEditServiceForm {
  &__form {
    display: flex;
    flex-direction: column;
    @include rem(padding, 32px 30px 0 14px);
    .Select__container {
      width: fit-content;
    }
  }

  &__footer {
    @include rem(padding, 18px 0 0);
    display: block;
  }

  &__input {
    display: flex;
    align-items: center;
    margin-left: auto;
    // @include rem(width, 289px);
    @include rem(margin-bottom, 15px);
    label {
      @include rem(margin-right, 12px);
    }
  }

  &__field {
    @include rem(width, 289px);

    resize: none;
  }
  &__select {
    @include rem(width, 289px);
  }
  &__switch {
    @include rem(margin-bottom, 36px);
    @include rem(margin-left, 50px);
  }
  &__service {
    display: flex;
    position: relative;
  }
  &__service_input {
    @include rem(padding-left, 46px);
  }
  &__error {
    top: -0.625rem;
  }
  &__color {
    position: absolute;
    @include rem(width, 30px);
    @include rem(left, 130px);
  }

  &__submit {
    @include rem(width, 184px);
    @include rem(padding, 12px 25px);
    @include rem(height, 43px);
    @include rem(font-size, 14px);
    // @include rem(line-height, 21px);
  }

  &__placeholder {
    @include rem(width, 150px);
    @include rem(height, 30px);
    @include rem(margin, 15px);
  }
}
