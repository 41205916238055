@import '../../styles/lib';

.AddStaffForm {
  @include rem(width, 253px);

  &__title {
    @include rem(font-size, 16px);
    @include rem(line-height, 25px);
    @include rem(padding-bottom, 14px);
    border-bottom: 1px solid $primary;
  }
  &__input {
    @include rem(margin, 20px 0);
  }
  &__wrapper_input {
    width: 100%;
  }
}
