@import '../../../styles/lib';

.Modal {
  top: 50%;
  transform: translateY(-50%) !important;
  margin: 0 auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);

  @include tablet {
    width: 70% !important;
  }
  @include mobile {
    width: 95% !important;
  }

  &__header {
    padding: 0;
    padding-left: 2px;
    @include rem(padding, 0px 2px 19px);
    h5 {
      font-weight: 400;
      @include rem(line-height, 27px);
      font-size: 18px;
      line-height: 27px;
    }
    @include tablet {
      padding: 0 2px 19px;
    }
  }
  .modal-content {
    border: 0;
    border-radius: 30px;
    padding: 25px 31px;

    @include tablet {
      padding: 20px 20px;
    }
  }
  h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.1em;
    text-align: center;
    color: #334144;
    margin-bottom: 30px;
  }
}
