@import '../../styles/lib';

.AppointmentNav {
  display: flex;
  padding: 0;
  margin: 0;

  &__link {
    text-decoration: none;
    @include rem(margin-right, 21px);
    line-height: 21px;
    border-bottom: 2px solid white;
    @include rem(font-size, 14px);
    color: #bbbbbb;
    @include tablet {
      margin-right: 15px;
    }
    &:disabled {
      border-bottom: 2px solid white;
      color: #bbbbbb;
    }
  }

  &__link_active {
    color: black;
    border-bottom: 2px solid $primary;
  }
}
