@import '../../../../styles/lib';

.ViewSwitcher {
  color: $terciary;
  display: flex;
  margin: 0;
  @include rem(font-size, 14px);
  @include tablet {
    font-size: 14px;
    line-height: 21px;
  }
  li {
    &:before {
      content: attr(data-text);
      font-weight: bold;
      display: block;
      visibility: hidden;
      overflow: hidden;
      height: 0px;
    }
  }

  &__item {
    position: relative;
    @include rem(margin-right, 10px);
    cursor: pointer;
  }
}
.active_view {
  color: black;
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    background-color: $primary;
    height: 2px;
    @include rem(bottom, -5px);
    left: 0;
  }
}
