@import '../../../styles/lib';

.Table {
  overflow-y: auto;
  // height: 50vh;
  table {
    width: 100%;
  }

  thead {
    tr {
      border-top: none !important;
      &:hover {
        border-bottom: none;
      }
    }
    line-height: 30px;
    color: $terciary;
  }

  td {
    line-height: 21px !important;
    @include rem(padding-top, 20px);
    @include rem(padding-bottom, 20px);
    vertical-align: middle;
    color: $terciary;
    @include rem(font-size, 18px);
  }

  tr {
    height: 23px;
    border-bottom: 1px solid #ddc9c0;
    &:last-child {
      border-bottom: none;
    }
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 30vh;
  }

  span {
    display: flex;
    flex-direction: column;
  }

  &__placeholder_header {
    margin-top: 5px;
  }

  &__placeholder_row {
    display: flex;
    @include rem(height, 18px);
    @include rem(margin, 20px 0 20px);
  }

  &__skeleton {
    @include rem(margin-top, 40px);
  }
}
