@import '../../../styles/lib';

.AppointmentsTable {
  height: 60vh;
  overflow-y: visible;
  &__popover {
    top: -1rem !important;
    left: -0.8rem !important;
    .arrow {
      @include rem(width, 15px);
      @include rem(height, 15px);
      border-radius: 0;
      left: 98.3%;
      top: 1.7rem;
      border-top: 1px solid $primary;
      border-right: 1px solid $primary;
      z-index: -1;
    }
  }
}
