@import '../../../styles/lib';

.NoBorderButton {
  width: fit-content;
  background-color: #fff;
  border: none;
  border-bottom: 2px solid $primary;
  @include rem(line-height, 18px);
  @include rem(font-size, 12px);
  border-radius: 0;
  padding: 0;
  @include rem(margin-right, 21px);
  &:hover {
    background-color: white;
    color: black;
    border: none;
    border-bottom: 2px solid $terciary;
  }
  &:active {
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }

  @include tablet {
    font-size: 12px;
    line-height: 18px;
  }
}
