@import '../../styles/lib';

.SeeMoreButton {
  background-color: transparent;
  border: 1px solid $primary;
  border-radius: 50%;
  @include rem(max-width, 30px);
  @include rem(height, 30px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  @include rem(padding, 9px 5px);
  &:hover {
    background-color: $primary;
    border: none;
    svg {
      filter: invert(100%) sepia(98%) saturate(0%) hue-rotate(275deg) brightness(115%) contrast(100%);
    }
  }

  &__icon {
    @include rem(min-width, 18px);
    @include rem(min-height, 12px);
    display: flex;
    align-items: center;
    justify-self: center;
  }
}
