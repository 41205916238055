@import '../../styles/lib';

.ConfirmationModal {
  @include rem(width, 380px);
  display: flex;
  justify-content: center;
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    @include rem(margin-top, 20px);
  }
  &__cancel {
    @include rem(height, 21px);
    @include rem(margin-left, 15px);
  }
  &__ok {
    width: fit-content;
  }
}
