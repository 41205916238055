@import '../../../../styles/lib';
.DateChanger {
  display: flex;
  position: relative;
  &__item_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    cursor: pointer;
    @include rem(height, 34px);
    @include rem(min-width, 34px);
    @include rem(margin-right, 5px);
    @include rem(margin-left, 5px);
    border-radius: 4px;
    @include tablet {
      height: 30px;
      width: 40px;
    }
  }
  @include tablet {
    width: 100%;
  }

  .nice-dates-popover {
    left: 0.32rem;
  }
  &__arrow {
    @include rem(height, 14px);
    @include rem(width, 9px);

    display: flex;
    justify-content: center;
    align-items: center;
    @include tablet {
      width: 11px;
      height: 9px;
    }

    path {
      height: 100%;
      width: 100%;
      fill: $black;
    }
  }

  &__date {
    @include rem(margin, 0 15px);
  }

  &__calendar_icon {
    @include rem(height, 23px);
    @include rem(width, 23px);

    g {
      stroke: $black;
    }
    @include tablet {
      height: 23px;
      width: 23px;
    }
  }
}

.left {
  transform: rotate(90deg);
}

.right {
  transform: rotate(270deg);
}
