@import '../../../styles/lib';

.StaffDetails {
  @include rem(padding-left, 15px);

  ul {
    @include rem(margin, 43px 75px 0 0);
    padding: 0;
    li {
      @include rem(line-height, 27px);
      @include rem(font-size, 18px);
      @include rem(margin-bottom, 10px);
    }
  }

  &__information {
    display: flex;
  }

  &__placeholder_name {
    width: 20% !important;
    @include rem(height, 24px);
    @include rem(margin, 5px 0 5px 0);
  }

  &__placeholder_description {
    width: 50% !important;
    @include rem(margin-bottom, 43px);
  }

  &__placeholder_info {
    width: 60%;
    @include rem(height, 18px);
    @include rem(margin, 3px);
  }

  &__placeholder {
    @include rem(padding-left, 15px);
    width: 45%;
  }
}
