@import '../../styles/lib';

.Reports {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    width: 100%;
    @include rem(padding, 68px 147px 52px);
    border-bottom: 1px solid $primary;
    @include rem(font-size, 24px);
    font-weight: 600;
    @include rem(line-height, 35px);
  }
}
