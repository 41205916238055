//====================== IMPORTANT NOTE: Do not change import order! =====================//

// Reset styling.
@import 'reset';

//Include lib. Override bootstrap and declare other variables.
@import 'lib';

//Include bootstrap.
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import 'bootstrap/bootstrap';

//Import other styles.
html,
body {
  font-family: $font-family-base;
  @include rem(line-height, 21px);
  @include rem(font-size, 14px);
  font-weight: 400;
}
