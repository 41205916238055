@import 'src/styles/lib';

.ForgotPassword {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('../../../assets/images/background-login.png');
  background-position: bottom;
  width: 100%;
  height: 100vh;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    button {
      background-color: transparent;
      color: white;
      width: 200px;
      margin-right: 20px;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
  }
  &__description {
    color: white;
    font-size: 32px;
    margin-bottom: 44px;
    display: inline-block;
    font-weight: 400;
  }
  &__email {
    input {
      width: 250px;
      height: 40px;
      margin-right: 20px;
    }
  }
  &__emails {
    margin-bottom: 45px;
  }
  &__content {
    max-width: 355px;
    color: white;
    font-size: 15px;
  }
}
