@import '../../../styles/lib';

.PasswordForm {
  width: 100%;
  &__input {
    width: 100%;
    margin-bottom: 15px;
    .Input__wrapper {
      width: 100%;
    }
  }
}
