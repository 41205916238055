@import '../../../styles/lib';

.CustomerInformation {
  @include rem(margin, 49px 64px 0 40px);
  min-width: 30%;

  &__content {
    display: flex;
  }

  h1 {
    @include rem(font-size, 24px);
    @include rem(line-height, 35px);
    @include rem(margin-bottom, 55px);
    font-weight: 600;
    white-space: nowrap;
  }

  ul {
    padding-left: 0;
  }

  li {
    @include rem(font-size, 18px);
    @include rem(line-height, 27px);
    @include rem(margin-bottom, 32px);
  }

  &__details {
    text-overflow: ellipsis;
    white-space: nowrap;
    @include rem(margin-left, 43px);
    li {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__placeholder {
    @include rem(margin, 49px 64px 0 40px);
    min-width: 30%;
  }

  &__line {
    @include rem(height, 18px);
    @include rem(margin-bottom, 32px);
    &_header {
      @include rem(margin-bottom, 75px);
    }
  }

  &__error {
    @include rem(margin, 49px 64px 0 40px);
  }
}
