@import '../../../styles/lib';

.AddEditTimeOffForm {
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  @include tablet {
    align-items: center;
  }
  @include rem(margin-top, 19px);

  &__switch {
    margin-right: 55%;
    @include rem(margin-bottom, 20px);
  }
  &__input {
    display: flex;
    @include rem(margin-bottom, 15px);

    label {
      @include rem(margin-right, 15px);
      @include rem(margin-top, 8px);
    }
  }
  &__wrapper_input {
    @include rem(width, 280px);
  }

  &__modalFooter {
    width: 100%;
    padding-bottom: 0;
  }

  &__error {
    width: 100%;
  }

  .FormButtons {
    width: 100%;
  }

  &__placeholder {
    width: 60%;
    height: 100%;
  }

  &__skeleton {
    @include rem(height, 34px);
    @include rem(margin-bottom, 20px);
  }
  &__content {
    @include rem(height, 60px);
    @include rem(margin-bottom, 20px);
  }
}
