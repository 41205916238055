@import '../../../styles/lib';
.CustomerDetailsForm {
  border: 1px solid $primary;
  @include rem(padding, 10px 10px 5px);
  display: flex;
  flex-direction: column;
  &__button {
    background-color: transparent;
    border: none;
    padding: 0;
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include rem(padding, 20px 5px 5px);
  }
  &__close {
    @include rem(width, 12px);
    @include rem(height, 12px);
    margin-left: auto;
  }
  &__input {
    @include rem(margin-bottom, 30px);
    display: flex;
    align-items: center;
    width: 100%;
    label {
      @include rem(margin-right, 30px);
      @include rem(min-width, 42px);
      @include tablet {
        margin-right: 15px;
      }
    }
    .Input__error {
      @include rem(margin-left, 30px);
    }
  }
  &__field {
    width: 100%;
  }
  &__birthDate {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    width: 100%;
    p {
      text-align: center;
      margin-right: 42px;
    }
  }
  &__datepicker {
    border: 1px solid $primary;
    width: 100%;
    border-radius: 5px;
    @include rem(margin, 20px 0);
    margin-top: 0;
    font-size: 1rem;
    padding: 0rem 0.75rem;
    .DatePicker__field {
      text-align: left;
      &::placeholder {
        font-size: 1em;
        display: block;
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: $terciary;
      }
    }
  }
}
