@import '../../../styles/lib';
.DatePicker {
  @include rem(height, 36px);
  @include rem(width, 140px);

  @include tablet {
    height: 30px;
    width: 100%;
  }

  &__error {
    color: $red;
  }
  &__label {
    @include rem(line-height, 20px);
    @include rem(margin-bottom, 7px);
  }
  &__field {
    outline: 0;
    @include rem(font-size, 14px);
    @include rem(height, 34px);
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    width: 100%;
    background-color: white;
    border: none;

    @include tablet {
      height: 28px !important;
    }
    // border: 1px solid #ccd9e2;

    &:focus {
      border-color: $black;
    }
    &::placeholder {
      @include rem(font-size, 12px);
      color: $black;
    }

    @include tablet {
      font-size: 14px;
      height: 30px;
    }
  }
  &__icon {
    position: absolute;
    @include rem(top, 32px);
    @include rem(right, 7px);
    @include rem(width, 21px);
    g {
      stroke: $black;
    }
  }
}
.nice-dates {
  width: 100%;
  z-index: 11;
}
//overwrite
.nice-dates-popover,
.-open {
  max-width: fit-content;
  @include rem(font-size, 12px);
  // @include rem(right, 512px);
  font-size: 12px;
  @include tablet {
    max-width: 95%;
    @include mobile {
      max-width: 70%;
    }
  }
}
.nice-dates-navigation {
  padding-top: 3px;
}
.nice-dates-navigation_previous {
  width: 100%;
  &::before {
    border-right: 2px solid black !important;
    border-top: 2px solid black !important;
    @include rem(margin-top, -3px);
    height: 8px;
    width: 8px;
  }
}
.nice-dates-navigation_next {
  width: 100%;
  &::before {
    display: none;
  }
  &::after {
    border-right: 2px solid black !important;
    border-top: 2px solid black !important;
    @include rem(margin-top, -3px);
    height: 8px;
    width: 8px;
    border-right: 2px solid #999;
    border-top: 2px solid #999;
    box-sizing: border-box;
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    transition: 200ms border-color;
  }
}
.nice-dates-navigation_current {
  @include rem(font-size, 14px);
  font-weight: 600;
  @include tablet {
    font-size: 12px;
  }
}
.nice-dates-week-header_day {
  @include rem(font-size, 12px);
  color: black;
  @include tablet {
    font-size: 8px;
  }
}
.nice-dates-day {
  @include rem(font-size, 12px);
  @include tablet {
    font-size: 8px;
  }
}
.nice-dates-day_month {
  display: none;
}
.nice-dates-navigation {
  display: flex;
  align-items: center;
  // justify-content: space-around;
}
.nice-dates-week-header {
  @include rem(padding, 16px 16px 5px);

  box-shadow: none;
}
.nice-dates-grid_container {
  @include rem(padding-left, 16px);
  @include rem(padding-right, 16px);
  @include rem(padding-bottom, 15px);
}
.nice-dates-grid {
  height: 170px !important;
}
.nice-dates-week-header_day {
  margin-right: 5px;
}
.nice-dates-day {
  height: 27px !important;
}
.nice-dates-day_date,
.nice-dates-day::before,
.nice-dates-day::after {
  // @include rem(line-height, 22px);
  // @include rem(height, 26px);
  // @include rem(width, 26px);

  // height: 23px;
  // width: 23px;
  // line-height: 23px;
  font-size: 12px;

  // @include mobile {
  //   width: 25px;
  //   height: 25px;
  //   line-height: 25px;
  // }
}
