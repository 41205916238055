@import '../../../styles/lib';

.InputWithElement {
  @include rem(height, 40px);
  border: 1px solid $primary;
  border-radius: 5px;

  @include tablet {
    height: 38px;
  }

  .Input__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    @include rem(padding-right, 5px);
    @include rem(width, 134px);
    @include tablet {
      @include rem(width, 105px);
      height: 100%;
    }
  }

  .Select__wrapper {
    margin: 0;
  }

  &__input {
    border: none;
    @include rem(width, 80px);
    @include rem(height, 36px);
    @include tablet {
      @include rem(width, 60px);
      padding: 5px;
      height: fit-content;
    }
  }
}
