@import '../../styles/lib';

.WorkingHoursForm {
  @include rem(margin-top, 43px);

  max-height: 100%;
  &__divider {
    width: 100%;
    border-top: 1px solid $primary;
    @include rem(margin, 50px 0 30px);
  }

  &__placeholder {
    @include rem(margin-top, 25px);
    display: flex;
  }

  &__skeleton {
    @include rem(margin, 20px 0);
    @include rem(height, 18px);
  }

  &__edit {
    @include rem(font-size, 18px);
    border-bottom: none;

    color: $primary;
    &:hover {
      color: black;
      border-bottom: none;
    }
  }

  &__skeleton {
    display: flex;
    flex-direction: column;
    width: 7rem !important;
    @include rem(margin-right, 125px);

    &:nth-child(3) {
      @include rem(margin-right, 86px);
    }
    .Skeleton {
      @include rem(margin-bottom, 28px);
    }
  }

  .FormButtons {
    justify-content: flex-end;
    flex-direction: row-reverse;
    &__submit {
      @include rem(margin-right, 21px);
      @include rem(font-size, 16px);
      @include rem(padding, 10px 25px);
    }
  }
}
