@import '../../../styles/lib';

.ErrorMessage {
  border-radius: 5px;
  margin-top: 10px;
  font-size: 12px;
  .close {
    background-color: transparent;
    border: none;
  }
  @include mobile {
    padding: 15px;
    font-size: 12px;
  }
}
