@import '../../../styles/lib';

.CustomerAppointments {
  width: 70%;
  @include rem(margin, 62px 134px 0 0);
  @include tablet {
    @include rem(margin, 49px 64px 0 40px);
  }
  @include tablet {
    width: 85%;
  }
  &__title {
    @include rem(font-size, 14px);
    width: fit-content;
    border-bottom: 1px solid black;
    margin: 0;
    @include rem(line-height, 23px);
  }

  &__divider {
    width: 100%;
    border-bottom: 1px solid #d1beb6;
    @include rem(margin-bottom, 51px);
  }

  &__summary {
    display: flex;
    align-items: center;
  }

  &__total {
    @include rem(margin-left, 44px);
  }

  &__icon {
    @include rem(width, 28px);
    @include rem(height, 28px);
  }

  &__table {
    overflow-y: auto;
    @include rem(margin-top, 12px);
    height: 70%;
    padding-right: 10px;
    td {
      position: relative;
    }
    tr:first-child {
      border-top: 1px solid $primary;
    }
  }

  &__time {
    @include rem(font-size, 16px);
    @include rem(line-height, 23px);
    color: black;
  }

  &__appointment_detail {
    @include rem(font-size, 12px);
    @include rem(line-height, 23px);
    color: #bbbbbb;
  }

  &__dropdown_menu {
    overflow: visible;
    top: -2.1rem !important;
    left: 0.8rem !important;
    pointer-events: all !important;
    transform: translate3d(-340px, -15px, 0px) !important;
    &::after {
      @include rem(width, 15px);
      @include rem(height, 15px);
      border-radius: 0;
      left: 98.5%;
      top: 1.7rem;
      border-top: 1px solid $primary;
      border-right: 1px solid $primary;
      z-index: -1;
    }
  }
}

.CustomerAppointmentsPlaceholder {
  width: 70%;
  @include rem(margin, 62px 134px 0 0);
  display: flex;
  flex-direction: column;

  &__table {
    width: 70%;
    @include rem(margin-bottom, 30px);
    @include rem(height, 24px);
  }
}
