@import '../../../styles/lib';

.AddCustomerForm {
  @include rem(width, 253px);

  &__input {
    @include rem(margin, 20px 0);
  }
  &__submit {
    @include rem(padding, 7px 20px);
    white-space: nowrap;
  }

  &__wrapper_input {
    width: 100%;
  }

  &__datepicker {
    border: 1px solid $primary;
    width: 100%;
    border-radius: 5px;
    @include rem(margin, 20px 0);
    font-size: 1rem;
    padding: 0rem 0.75rem;
    .DatePicker__field {
      text-align: left;
      &::placeholder {
        font-size: 1em;
        display: block;
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: $terciary;
      }
    }
  }
}
