@import '../../../../styles/lib';

.Toolbar {
  @include tablet {
    margin-bottom: 15px;
    padding-left: 30px;
  }
  @include mobile {
    margin-bottom: 5px;
    padding-left: 5px;
  }
  &__content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    @include rem(height, 83px);
    @include rem(padding-left, 125px);
    @include tablet {
      height: 50px;
      padding: 0;
    }
  }

  &__staffForm {
    .Dropdown__menu {
      &::after {
        @include rem(width, 15px);
        @include rem(height, 15px);
        @include rem(border-radius, 3px);
        border-top: 1px solid $primary;
        border-left: 1px solid $primary;
        top: -0.5rem;
        left: 2.3rem;
      }
    }
  }

  &__alert {
    @include rem(padding, 8px 22px);
    margin: auto;
  }

  &__pagination {
    width: 80%;
    @include rem(margin-top, 30px);
    @include rem(height, 30px);
    display: flex;
    align-items: center;
    @include tablet {
      width: 100%;
      margin-top: 5px;
      justify-content: flex-end;
      height: fit-content;
    }
  }
  &__right-side {
    display: flex;
    height: 100%;
    align-items: center;
    @include tablet {
      max-width: 70%;
      width: 65%;
    }
  }

  &__actions {
    display: flex;
    @include rem(padding, 0 18px);
    & > * {
      margin: auto;
      @include rem(margin-right, 12px);
      @include rem(margin-left, 12px);
    }
  }

  &__filters {
    display: flex;
    line-height: 0.9rem;
    margin-left: auto;
    flex-direction: column;
    margin-left: 12px;
  }
  &__filter {
    display: flex;
    @include rem(margin-right, 45px);
    align-items: center;
  }
  &__artist {
    @include rem(width, 20px);
    @include rem(height, 22px);
    margin-right: 8px;
    @include tablet {
      width: 18px;
      height: 20px;
    }
  }

  &__navigation {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: rgba(221, 201, 192, 0.25);
    @include rem(border-bottom-left-radius, 52px);
    @include rem(border-top-right-radius, 52px);
    @include rem(padding, 0 42px);
    @include tablet {
      width: 100%;
      justify-content: flex-end;
      @include rem(border-bottom-left-radius, 15px);
      @include rem(border-top-right-radius, 15px);
      padding: 0 5px;
    }
  }
  &__today_button {
    background: transparent;
    border: 1px solid #808080;
    color: #808080;
    line-height: 1rem;
    @include rem(border-radius, 4px);
    @include rem(padding, 4px 3px 5px 5px);
    &:hover {
      color: black;
      border-color: black;
    }
  }

  &__view_switcher {
    @include rem(margin-left, 25px);
  }

  &__icon {
    cursor: pointer;
    &:hover {
      g {
        stroke: black;
      }
    }
  }
  .eye {
    @include rem(width, 26px);
    @include rem(height, 18px);
    svg {
      @include rem(width, 26px);
      @include rem(height, 18px);
    }
  }
  .refresh {
    @include rem(width, 19px);
    @include rem(height, 18px);
    svg {
      @include rem(width, 19px);
      @include rem(height, 18px);
    }
  }
  .printer {
    @include rem(width, 20px);
    @include rem(height, 18px);
    svg {
      @include rem(width, 20px);
      @include rem(height, 18px);
    }
  }
}
