@import '../../../../styles/lib';

.NavSidebarItem {
  @include rem(min-height, 93px);
  text-decoration: none;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  @include rem(margin-bottom, 15px);
  align-items: center;
  text-align: center;
  color: $secondary;
  cursor: pointer;

  &__icon {
    @include rem(height, 29px);
    @include rem(width, 29px);
    @include rem(margin-bottom, 8px);
  }
}

.NavSidebarItem__active {
  position: relative;
  color: white !important;
  background-color: $primary;
  @include rem(border-radius, 47px 0 0 47px);
  color: white;
  svg {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(10deg) brightness(104%)
      contrast(106%);
  }

  &:before {
    content: '';
    position: absolute;
    @include rem(border-bottom-right-radius, 100px);
    @include rem(height, 15px);
    @include rem(width, 9px);
    @include rem(top, -15px);
    background-color: white;
    box-shadow: 0 8px 0 0 $primary;
    right: 0;
  }
  &:after {
    content: '';
    @include rem(border-top-right-radius, 100px);
    @include rem(height, 15px);
    @include rem(width, 9px);
    @include rem(bottom, -15px);
    background-color: white;
    box-shadow: 0 -8px 0 0 $primary;
    position: absolute;
    right: 0;
  }
}
