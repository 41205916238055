@import '../../styles/lib';

.BreaksForm {
  @include rem(margin-top, 43px);

  &__input {
    @include rem(margin-left, 125px);
  }

  &__placeholder {
    display: flex;
    flex-direction: row;
    @include rem(margin-top, 43px);
  }

  &__skeleton {
    display: flex;
    flex-direction: column;
    width: 7rem !important;
    @include rem(margin-right, 125px);

    &:nth-child(3) {
      @include rem(margin-right, 86px);
    }
    .Skeleton {
      @include rem(margin-bottom, 28px);
    }
  }
}
