@import '../../styles/lib';

.ChooseWorkingHour {
  display: flex;
  align-items: center;
  @include rem(margin-bottom, 28px);
  @include tablet {
    display: block;
  }
  &__select {
    @include rem(width, 90px);
    @include rem(margin, 0 55px);
  }
  .Select__wrapper {
    margin: 0;
    @include tablet {
      justify-content: center;
    }
  }

  &__switch_hidden {
    visibility: hidden;
  }

  &__label {
    @include rem(width, 85px);
    @include rem(margin-right, 70px);
  }

  &__off {
    color: $primary;
    @include rem(line-height, 34px);
    @include rem(margin, 0 60px);
    @include rem(font-size, 16px);
    @include rem(height, 38px);
    @include tablet {
      display: flex;
      justify-content: center;
    }
  }
  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    @include tablet {
      display: flex;
      justify-content: center;
      @include rem(margin-right, 162px);
    }
  }
}
.Switch {
  justify-content: flex-end;
}
