.ApprovedMessage {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__title {
    font-size: 40px;
    font-weight: 600;
  }
  &__description {
    width: 80%;
    text-align: center;
    font-size: 16px;
  }
  &__button {
    background-color: transparent;
    color: white;
  }
}
