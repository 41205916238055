@import '../../styles/lib';

.ReportInformation {
  @include rem(height, 89px);
  background-color: #fcfbfb;
  display: flex;
  align-items: center;
  h2 {
    color: $primary;
    @include rem(font-size, 20px);
    @include rem(line-height, 30px);
  }
  &__dates {
    @include rem(font-size, 14px);
    @include rem(line-height, 21px);
  }

  &__arrow {
    @include rem(width, 18px);
    @include rem(height, 11px);
    transform: rotate(270deg);
    @include rem(margin-left, 92px);
  }

  &__time {
    @include rem(padding, 19px);
  }
  &__divider {
    @include rem(height, 52px);
    @include rem(margin, 18px 28px 18px 28px);
    border-right: 1px solid $primary;
  }

  h3 {
    @include rem(font-size, 20px);
    font-weight: 600;
  }

  &__appointments {
    @include rem(padding-right, 103px);
    @include mobile {
      padding-right: 0px;
    }
  }
  &__revenue {
    @include rem(padding-right, 131px);
    @include mobile {
      padding-right: 0px;
    }
  }
}
