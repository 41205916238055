@import '../../../styles/lib';

.Sidebar {
  @include rem(padding-left, 7px);
  @include rem(padding-right, 10px);
  @include rem(padding-top, 56px);
  height: 100%;
  width: 100%;
  @include rem(max-width, 322px);
  background: $secondary;
  @include rem(border-radius, 70px 0px 0px 190px);
  // z-index: 9999;
  @include small-desktop {
    border-radius: 0;
  }
  &__skeleton {
    @include rem(width, 290px);
    @include rem(height, 44px);
    @include rem(margin-top, 8px);
  }

  &__search {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    @include rem(margin-bottom, 13px);
  }
  &__input_wrapper {
    @include rem(width, 276px);
    @include rem(margin-right, 16px);
  }

  &__icon {
    @include rem(width, 15px);
    @include rem(height, 15px);
    position: absolute;
    right: 1rem;
    // top: 8px;
  }

  &__input {
    @include rem(margin-left, 14px);
    @include rem(width, 276px);
    @include rem(height, 34px);
  }

  &__title {
    color: white;
    font-weight: 600;
    @include rem(margin-left, 22px);
    @include rem(font-size, 18px);
    @include rem(line-height, 23px);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
