@import '../../../styles/lib';
.Content {
  position: relative;
  overflow-x: auto;
  grid-area: content;
  background-color: $white;
  @include rem(margin, 0 0 46px 46px);
  @include rem(border-radius, 70px 70px 70px 190px);
  box-shadow: 0 50px 40px rgba(238, 218, 218, 0.5);

  @include small-desktop {
    margin: 0 0 10px 10px;
    @include rem(border-radius, 15px 15px 15px 15px);
  }
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 4;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      height: 100%;
      width: 100%;
      background-color: $black;
      opacity: 0.2;
    }
  }
}
