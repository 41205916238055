@import '../../../styles/lib';

.Switch {
  display: flex;
  align-items: center;
  &__label {
    @include rem(margin-right, 15px);
  }
  &__container {
    @include rem(width, 52px);
    height: 23px;
    background-color: $primary;
    cursor: pointer;
    user-select: none;
    border-radius: 30px;
    padding: 2px;
    position: relative;
  }

  &__button {
    font-weight: bold;
    cursor: pointer;
    background-color: white;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19px;
    height: 19px;
    min-width: unset;
    border-radius: 50%;
    box-sizing: border-box;
    position: absolute;
    @include rem(left, 28px);
    transition: all 0.3s ease;
  }

  &__disabled {
    background-color: white;
    left: 2px;
  }
  &__text {
    position: absolute;
    color: white;
    text-align: center;
    @include rem(top, 5px);
    @include rem(padding-left, 4px);
    @include rem(font-size, 12px);
    @include rem(line-height, 18px);
    transition: all 0.5s ease;
  }
  &__text_disabled {
    position: absolute;
    text-align: center;

    @include rem(left, 22px);
  }
}
