@import '../../../styles/lib';

.FormButtons {
  display: flex;
  align-items: center;
  justify-content: center;

  &__submit_field {
    padding: 9px 12px;
  }
  @include tablet {
    margin-top: 15px;
  }
}
