@import '../../../styles/lib';

.DateAndTimePicker {
  display: flex;
  align-items: center;
  label {
    white-space: nowrap;
    @include rem(margin-right, 15px);
  }
  &__wrapper {
    display: flex;
    align-items: center;
    @include rem(margin-bottom, 15px);
  }

  &__datepicker {
    display: flex;
    border: 1px solid $primary;
    @include rem(border-radius, 5px);
    @include rem(width, 280px);
    align-items: center;
    @include rem(height, 38px);
  }

  .nice-dates {
    width: 60%;
    height: 100%;
  }
  &__date {
    width: 100%;
    height: 100%;
  }
  .Select__wrapper {
    margin: 0;
    width: 40%;
  }
  &__divider {
    border-right: 1px solid $primary;
    @include rem(width, 1px);
    @include rem(height, 26px);
  }
  &__field {
    width: 100%;
    height: 100%;
    @include rem(line-height, 21px);
    text-align: start;
    @include rem(padding, 9px 16px);
  }

  &__select {
    width: 100%;
  }
}
