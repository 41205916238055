@import '../../../styles/lib';

.Select {
  @include rem(width, 289px);
  @include tablet {
    width: 220px !important;
    min-height: 30px;
  }
  &__container {
    width: fit-content;
  }
  &__wrapper {
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__label {
    @include rem(margin-right, 12px);
    @include tablet {
      font-size: 14px;
      margin-right: 8px;
    }
  }
  &__icon {
    width: 10px;
    height: 6px;
  }
  &__skeleton {
    width: 100%;
    @include rem(height, 38px);
    margin-left: auto;
  }
  &__placeholder {
    @include rem(height, 38px);
    @include rem(margin-bottom, 15px);
    width: 100%;
    margin-left: auto;
  }
  &__error {
    color: red;
    @include rem(margin-left, 10px);
  }
}
