@import '../../styles/lib';

.ServiceNameInput {
  &__options {
    @include rem(width, 12px);
    @include rem(height, 12px);
  }

  &__service {
    display: flex;
    position: relative;
  }

  &__color {
    position: absolute !important;
    @include rem(width, 30px);
    left: 28%;
    @include tablet {
      left: 30%;
    }
    top: 10%;
    .css-1bweh2x-control {
      background-color: #e9ecef;
    }
    .Select__icon {
      position: absolute;
      right: 2%;
      top: 10px;
    }
  }

  &__service_input {
    @include rem(padding-left, 46px);
    width: 50px;
  }

  &__error {
    top: -0.625rem;
  }
}
