@import '../../../styles/lib';

.AddTimeOffView {
  @include rem(margin-top, 48px);
  &__add {
    @include rem(width, 887px);
    @include small-desktop {
      width: 95%;
    }
    @include rem(height, 106px);
    border: 1px solid $primary;
    @include rem(border-radius, 7px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__description {
    @include rem(font-size, 18px);
    @include rem(line-height, 21px);
    @include rem(margin-bottom, 14px);
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    label {
      @include rem(margin-right, 9px);
    }
  }
}
