@import '../../../../styles/lib';

.SidebarList {
  overflow-x: auto;
  width: 100%;
  padding-left: 0;
  @include rem(margin-top, 8px);
  @include rem(max-height, 450px);
  @include rem(padding-right, 11px);

  /* width */
  &::-webkit-scrollbar {
    @include rem(width, 4px);
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #ffffff;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }

  &__item_selected {
    background: rgba(16, 15, 15, 0.3);
  }
  &__noData {
    font-size: 14px;
    color: white;
    @include rem(margin-top, 8px);
    @include rem(padding, 12px 0 12px 14px);
  }
  &__delete {
    display: none;
    @include rem(width, 14px);
    @include rem(height, 16px);
    @include rem(margin-right, 15px);
    &:hover {
      filter: invert(32%) sepia(82%) saturate(7118%) hue-rotate(353deg) brightness(107%) contrast(137%);
    }
  }
  &__active {
    display: block;
  }
  &__item {
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include rem(font-size, 14px);
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    border-radius: 4px;
    @include rem(padding, 12px 0 12px 14px);
    &:hover {
      background: rgba(16, 15, 15, 0.3);
      .SidebarList__delete {
        display: block;
      }
    }
  }
  &__error {
    @include rem(font-size, 8px);
  }
}
