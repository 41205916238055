@import '../../styles/lib';

.AppointmentDetailsForm {
  @include rem(padding, 0 10px 0);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @include tablet {
    justify-content: center;
    align-items: center;
  }
  &__select {
    @include rem(width, 280px);
  }

  &__service {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    @include tablet {
      @include rem(margin-left, 40px);
    }
    @include rem(width, 280px);
    margin-bottom: 10px;
    @include tablet {
      width: 220px;
    }
  }
  &__duration {
    width: 4rem !important;
  }

  &__input {
    display: flex;

    // align-items: center;
    width: fit-content;
    // @include rem(width, 280px);
    @include rem(margin-right, 8px);
    margin-left: auto;
    @include rem(margin-bottom, 15px);
    margin-bottom: 0;

    label {
      @include rem(margin-right, 12px);
      @include rem(margin-top, 10px);
      @include tablet {
        font-size: 14px;
      }
    }
  }
  &__content {
    display: flex;
    @include tablet {
      width: 82%;
    }
    p {
      margin-left: 29px;
    }
  }
  .discount {
    width: auto;

    @include rem(width, 135px);
    margin-left: 9px;
    margin-bottom: 10px;
  }

  .Select__container {
    width: fit-content;
  }

  &__notes {
    display: flex;
    margin: 0;

    margin-left: auto;
    @include tablet {
      margin-left: 0;
    }
    label {
      margin-right: 8px;
      @include tablet {
        padding-top: 5px;
      }
    }
  }

  &__notes_field {
    @include rem(width, 280px);
    @include rem(height, 80px);
    margin: 0;
    resize: none;

    @include tablet {
      width: 220px;
      height: 60px;
    }
  }

  &__time {
    margin-left: auto;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    @include tablet {
      margin-left: 0;
    }
    label {
      white-space: nowrap;
      text-overflow: ellipsis;
      @include rem(margin-right, 12px);
      @include tablet {
        font-size: 14px;
      }
    }
  }

  &__date {
    @include rem(width, 280px);
    @include tablet {
      width: 220px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    @include rem(margin-top, 29px);

    border-top: 1px solid $primary;
    @include rem(padding-top, 20px);
    @include tablet {
      margin-top: 29px;
      padding-top: 20px;
    }
  }

  &__submit {
    @include rem(width, 170px);
    @include rem(height, 39px);
    @include tablet {
      width: 140px;
      height: 39px;
      margin-left: 12px;
    }
  }
}
