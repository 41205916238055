@import '../../styles/lib';

.Services {
  display: flex;
  width: 100%;
  height: 100%;
  @include tablet {
    flex-direction: column;
  }
}
