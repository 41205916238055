@import '../../../../styles/lib';

.StaffServices {
  &__table {
    @include rem(margin-top, 37px);
    tr {
      &:hover {
        border-bottom: 1px solid black;
        td {
          color: black;
        }
        .StaffServices__delete {
          display: block;
          cursor: pointer;
        }
      }
    }
  }

  &__delete {
    display: none;
    @include rem(width, 15px);
    @include rem(height, 17px);
    filter: invert(32%) sepia(82%) saturate(7118%) hue-rotate(353deg) brightness(107%) contrast(137%);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    @include rem(margin, 0 63px 0 15px);
  }

  .Dropdown__menu {
    left: -400% !important;
    &::after {
      @include rem(top, -9px);
      @include rem(left, 240px);
      border-top: 1px solid $primary;
      border-left: 1px solid $primary;
      border-radius: 0;
      @include rem(width, 18px);
      @include rem(height, 18px);
    }
  }
}
