@import '../../../styles/lib';

.Dropdown {
  z-index: 88;
  &__menu {
    opacity: 1 !important;
    position: relative;
    // transition-property: transform, opacity;
    // transition-duration: 400ms;
    // transition-timing-function: cubic-bezier(0.4, 0.2, 0, 1.1);
    // will-change: transform;
    padding: 0;
    box-shadow: 0 3px 6px $primary;
    @include rem(padding, 24px 31px);
    @include rem(border-radius, 23px);
    left: -18px !important;
    top: 8px !important;
    z-index: 0;
    &::after {
      content: '';
      position: absolute;
      @include rem(width, 35px);
      @include rem(height, 35px);
      border-bottom: 0;
      border-right: 0;
      border-radius: 5px;
      bottom: 100%;
      left: 8%;
      z-index: -5;
      transform: rotate(45deg);
      background-color: white;
      @include rem(margin-bottom, -32px);
      @include tablet {
        margin-left: -95px;
      }
      @include mobile {
        margin-left: -95px;
      }
    }
  }

  &__title {
    @include rem(font-size, 16px);
    @include rem(line-height, 25px);
    @include rem(padding-bottom, 14px);
    border-bottom: 1px solid $primary;
    z-index: 9999;
  }
  &__item {
    @include rem(font-size, 14px);
  }
  &__toggle {
    background-color: transparent;
    border: none;
    box-shadow: none;
    &:active {
      box-shadow: none;
      background-color: transparent;
      border: none;
      outline: none;
    }
    &:hover {
      box-shadow: none;
      background-color: transparent;
      border: none;
    }
    &:focus {
      box-shadow: none;
      background-color: transparent;
      border: none;
    }
  }
}
