.Page404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__error {
    font-size: 14px;
    color: red;
  }
  &__icon {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
  }
  &__message {
    margin-top: 5px;
    text-align: center;
    color: white;
    font-size: 18px;
  }
}
