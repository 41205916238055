@import '../../../styles/lib';

.EditService {
  &__placeholder {
    display: flex;
    flex-direction: column;
  }
  &__skeleton {
    display: flex;
    flex-direction: column;
    @include rem(height, 38px);
  }
}
