@import '../../styles/lib';

.BreaksFormField {
  display: flex;
  @include tablet {
    flex-direction: column;
  }
  @include rem(min-height, 40px);
  margin-bottom: 18px;
  label {
    @include rem(width, 90px);
  }

  .Select__wrapper {
    margin: 0;
  }

  &__placeholder {
    @include rem(height, 120px);
  }

  &__add {
    @include rem(height, 30px);
    @include rem(width, 123px);
    @include rem(margin-left, 125px);
    @include tablet {
      margin-left: 75%;
    }
    @include rem(border-radius, 5px);
    background-color: $primary;
    color: white;
    @include rem(font-size, 18px);
    @include rem(line-height, 21px);
    @include rem(padding, 3px 8px);
    &:hover {
      background-color: white;
      color: $primary;
    }

    &:disabled {
      opacity: 1;

      background-color: $primary;
      color: white;
      border: 1px solid $primary;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    @include tablet {
      flex-direction: column;
    }
    @include rem(margin-bottom, 10px);

    // .ErrorMessage {
    //   @include rem(height, 20px);
    //   @include rem(padding, 5px 10px);
    // }
  }
  &__select {
    @include rem(width, 90px);
    @include rem(margin, 0 55px);
  }

  &__delete {
    @include rem(width, 30px);
    @include rem(height, 30px);
    border: 1px solid $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    @include rem(margin-left, 20px);
    background-color: white;
    border-radius: 50%;

    &:hover {
      background-color: $primary;
      svg {
        filter: none;
      }
    }
    &:disabled {
      background-color: white;
    }
  }

  &__trash {
    @include rem(width, 12px);
    @include rem(height, 13px);
    svg {
      filter: invert(100%) sepia(92%) saturate(32%) hue-rotate(306deg) brightness(102%) contrast(101%);
    }
  }

  &__error {
    @include rem(padding, 3px 20px 3px 10px);
    margin: 0;
    @include rem(margin-left, 20px);
  }
}
