@import '../../../styles/lib';

.Button {
  background-color: white;
  border-radius: 25px;
  border: 1px solid $border-color;
  // @include rem(width, 128px);
  @include rem(font-size, 14px);
  @include rem(line-height, 18px);
  @include rem(padding, 10px 18px);

  @include tablet {
    line-height: 18px;
  }

  &:disabled {
    background-color: white;
    cursor: not-allowed;
  }
  &:focus {
    color: black;
    outline: none;
    box-shadow: none;
    background-color: white;
  }
  &:hover {
    background-color: $border-color;
    border: 1px solid $border-color;
    color: white;
  }
  &:active {
    color: white;
    box-shadow: none !important;
    outline: none !important;
  }

  &__spinner {
    @include rem(width, 20px);
    @include rem(height, 20px);
  }
}
