@import '../../../../styles/lib';

.AccountItems {
  &__dropdown_header {
    display: flex;
    align-items: center;
    color: black;
    justify-content: center;
    font-weight: bold;
    @include rem(width, 14px);
    background-color: white !important;
    width: 50% !important;
    @include rem(height, 53px);
    @include rem(padding, 12px);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    &:active {
      color: black;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 228px;
      width: 0.6rem;
      height: 228px;
      height: 0.6rem;
      right: 5.65rem;
      bottom: 0;
      top: 2.7rem;
      border-bottom-left-radius: 228px;
      border-bottom-left-radius: 50rem;
      box-shadow: -0.188rem 0.188rem 0 0.188rem white;
      @include tablet {
        top: 1.9rem;
        right: 6rem;
        transform: rotate(270deg);
      }
      @include mobile {
        top: 2.27rem;
        right: 8.76rem;
        transform: rotate(270deg);
      }
    }
    &:hover {
      color: grey;
    }

    @include tablet {
      font-size: 16px;
      height: 40px;
      width: 50% !important;
      margin-left: auto;
    }
    @include mobile {
      width: 70% !important;
    }
  }

  &__dropdown_header_icon {
    min-width: 10px;
    height: 6px;
    color: grey;
    margin-left: 5px;
    @include tablet {
      width: 10px;
      height: 6px;
      margin-left: 5px;
    }
  }

  &__dropdown_item {
    display: flex;
    align-items: center;
    @include rem(line-height, 21px);
    @include rem(padding, 7px 28px 7px 12px);
    &:hover {
      background-color: rgba(221, 201, 192, 0.25);
    }
    &:last-child {
      @include rem(padding, 12px 11px);
      @include rem(border-bottom-right-radius, 10px);
      @include rem(border-bottom-left-radius, 10px);
      @include tablet {
        padding: 12px 11px;
      }
    }
    &:active {
      background-color: rgba(221, 201, 192, 0.25);
      color: black;
    }
    @include tablet {
      height: fit-content;
      line-height: 21px;
      font-size: 16px;
      padding: 7px 28px 7px 12px;
    }
  }

  &__dropdown_icon {
    @include rem(width, 19px);
    @include rem(height, 19px);
    display: flex;
    align-items: center;
    justify-content: center;
    @include tablet {
      min-width: 19px;
      height: 19px;
    }
  }

  &__dropdown_content {
    @include rem(margin-left, 14px);
    @include tablet {
      width: 100%;
      margin-left: 12px;
    }
  }

  &__dropdown_details {
    @include rem(font-size, 12px);
    color: #b7b7b7;
    @include tablet {
      font-size: 12px;
      width: 100%;
    }
  }

  &__dropdown_email {
    @include rem(width, 120px);
    overflow: hidden;
    text-overflow: ellipsis;
    @include tablet {
      width: 80%;
    }
  }

  &__wrapper {
    background-color: white;
    border-radius: 8px;
    border-top-left-radius: 0;
    @include tablet {
      border-top-left-radius: 8px;
      border-top-right-radius: 0;
    }
  }
}
