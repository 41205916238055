$font-family-sans-serif: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-family-base: 'Poppins', sans-serif;

$primary: #ddc9c0;
$secondary: #917b70;
$terciary: #808080;
$bg-calendar-header: rgba(221, 201, 192, 0.25);
$border-color: #e5d6d0;
$green: #71e200;
$red: #f0142f;
$input-radius: 10px;
$white: white;
$black: black;
$mq-small: 767px;
$mq-medium: 992px;
$d-small: 1024px;
$d-medium: 1240px;

/*===================================
=            TRANSITIONS            = 
===================================*/
/// Google Material Design cubic bezier transition
/// @type Transition
$transition1: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
/// Google Material Design cubic bezier transition slower
/// @type Transition
$transition2: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
/*=====  End of TRANSITIONS  ======*/
