@import '../../../styles/lib';

.UserDetailsModal {
  @include rem(width, 470px);

  &__form {
    @include rem(padding, 30px 24px 0 20px);
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    @include tablet {
      align-items: center;
      padding: 30px 0 0 0;
    }
  }

  &__footer {
    @include rem(margin-top, 28px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__submit {
    font-size: 14px;
    @include rem(padding, 10px 66px);
    padding: 10px 66px;
    @include tablet {
      padding: 10px 44px;
    }
  }
}
