@import '../../styles/lib';

.DatePickerWithTime {
  display: flex;
  border: 1px solid $primary;
  align-items: center;
  border-radius: 5px;
  @include tablet {
    .nice-dates-popover {
      left: 0;
    }
  }

  .Select__wrapper {
    margin: 0;
  }
  &__date {
    @include rem(width, 163px);
    .DatePicker__field {
      text-align: start;
      @include rem(height, 36px);
      @include rem(padding-left, 16px);
    }
    @include tablet {
      width: fit-content;
    }
  }
  &__divider {
    border-right: 1px solid $primary;
    @include rem(width, 1px);
    height: 26px;
  }
  &__select {
    @include rem(width, 100px);
    @include tablet {
      width: 70px !important;
    }
  }
}
