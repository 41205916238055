@import '../../../styles/lib';

.Title {
  h1 {
    @include rem(font-size, 24px);
    @include rem(line-height, 35px);
    font-weight: 600;
    margin: 0;
  }

  &__placeholder {
    width: 25% !important;
    @include rem(height, 24px);
    @include rem(margin-bottom, 6px);
    &_description {
      width: 40% !important;
    }
  }
}
