@import '../styles/lib';

.PrivateRoute {
  transition: $transition1;
  width: 100vw;
  height: 100vh;

  display: grid;
  grid-template-columns: 13rem 1fr;
  grid-template-rows: 7.5rem 1fr 5rem;
  grid-template-areas: 'header header' 'sidebar content' 'footer footer';

  height: auto;

  @include rem(padding-right, 112px);

  @include tablet {
    padding: auto;
    padding-right: 0;
  }
  @include mobile {
    margin-right: 8px;
    padding-right: 0;
  }

  background-color: $primary;
  @include small-desktop {
    min-height: 100vh;
    height: -webkit-fill-available;
  }
  @include tablet {
    min-height: 100vh;
    height: -webkit-fill-available;
    grid-template-areas: 'header header' 'content content' 'footer footer';
    grid-template-columns: 13rem 1fr;
    grid-template-rows: 10rem 1fr 8rem;
  }

  @include mobile {
    grid-template-rows: 6rem 1fr 3rem;
  }

  &--closed-sidebar {
    grid-template-columns: 0px 1fr;
  }
}
