.ResetPassword {
  width: 100%;
  &__message {
    display: flex;
    flex-direction: column;
    h1 {
      color: white;
      font-size: 40px;
    }
    p {
      color: white;
      font-size: 19px;
    }
    button {
      background-color: transparent;
      color: white;
    }
  }
}
