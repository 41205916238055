@import '../../../styles/lib';

.AppointmentDetails {
  @include rem(width, 324px);
  @include tablet {
    width: 90%;
  }
  &__timeline {
    display: flex;
    align-items: center;
    @include rem(margin-top, 13px);
  }
  &__time {
    @include rem(font-size, 12px);
    @include rem(line-height, 18px);
  }
  &__status {
    margin-left: auto;
    @include rem(font-size, 14px);
    color: #bbbbbb;
    @include rem(line-height, 21px);
  }

  &__content {
    @include rem(margin-top, 35px);
    @include rem(padding-bottom, 19px);
    th,
    td {
      @include rem(padding-bottom, 32px);
    }
    th {
      @include rem(width, 120px);
      @include rem(font-size, 14px);
      color: #bbbbbb;
      margin-bottom: 0;
      @include rem(padding-right, 44px);
    }
    tr {
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &__edit {
    background-color: transparent;
    border: none;
    color: #71e200;
    padding: 0;
    @include rem(font-size, 14px);
    &:hover {
      background-color: transparent;
      color: $primary;
      border: none;
    }
    &:focus {
      color: #71e200;
    }
  }
  &__delete {
    background-color: transparent;
    border: none;
    padding: 0;
    @include rem(font-size, 14px);
    color: #f0142f;
    &:hover {
      border: none;
      background-color: transparent;
      color: $primary;
    }
    &:focus {
      color: #f0142f;
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    @include rem(padding-top, 19px);
    border-top: 1px solid $primary;
  }
}
