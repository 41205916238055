@import '../../../styles/lib';

.NoDataFound {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  &__message {
    font-size: 16px;
    color: $secondary;
    margin-top: 15px;
  }
  &__icon {
    height: 100px;
    width: 100px;
  }
}
