@import '../../../styles/lib';

.ServicesById {
  overflow-y: auto;
  width: 100%;

  &__table {
    @include rem(margin-top, 30px);
    overflow-y: auto;
    height: 55vh;
    tr {
      cursor: pointer;
      td:nth-child(4) {
        text-align: end;
      }
      td:nth-child(5) {
        display: flex;
        justify-content: center;
      }
      &:hover {
        border-bottom: 1px solid black;
        td {
          color: black;
        }
        .AllServices__delete {
          display: block;
        }
      }
    }
  }
  .AllServices__delete {
    display: none;
    @include rem(width, 15px);
    @include rem(height, 17px);
    filter: invert(32%) sepia(82%) saturate(7118%) hue-rotate(353deg) brightness(107%) contrast(137%);
  }
}
