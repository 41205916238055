@import '../../styles/lib';

.IncomeViewer {
  @include rem(margin-left, 62px);
  @include rem(margin-right, 56px);

  @include tablet {
    display: flex;
  }

  &__description {
    color: #b7b7b7;
  }
  &__amount {
    color: $terciary;
  }

  &__skeleton {
    @include rem(width, 200px);
    @include rem(height, 200px);
  }

  &__error {
    color: $red;
  }
}
