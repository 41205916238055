@import '../../styles/lib';
.Customers {
  display: flex;
  width: 100%;
  height: 100%;
  @include tablet {
    flex-direction: column;
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  &__pages {
    flex: 1;
    height: 100%;
    overflow: scroll;
    width: 100%;
  }
}
