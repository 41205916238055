@import '../../../styles/lib';

.Footer {
  position: relative;
  grid-area: footer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  width: 100%;
  background-color: white;
  @include rem(border-radius, 0 42px 0 0);
  @include rem(padding, 0 40px 0 100px);

  @include tablet {
    @include rem(border-radius, 15px);
    margin-right: 1px;
  }

  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 8px;
    height: 50px;
  }
  color: rgba(0, 0, 0, 0.5);
  ul {
    display: flex;
    margin-bottom: 0;

    li {
      a {
        text-decoration: none;
        color: black;
        opacity: 0.5;
        @include rem(margin-right, 30px);
        &:hover {
          opacity: 1;
          cursor: pointer;
          color: black;
        }
      }
    }
  }
}
