@import '.././../../../styles/lib';
.CalendarHeader {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  font-weight: 400;
  // @include rem("padding", 37px 0 5px 0);
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include rem(width, 55px);
    border-radius: 100%;
    height: 100%;
  }

  &__day {
    margin-bottom: 5px;
    @include rem(line-height, 21px);
    @include rem(font-size, 14px);
  }
  &__number {
    @include rem(font-size, 24px);
    // @include rem(line-heiht, 24px);
    margin: 0;
  }
}
