@import '../../styles/lib';

.AppointmentCustomerForm {
  .Input__wrapper {
    width: 100%;
    @include rem(height, 38px);
  }
  &__wrapper {
    width: 100%;
  }
  &__input {
    width: 100%;
    @include rem(height, 32px);
  }
  &__search {
    @include rem(width, 15px);
    @include rem(height, 15px);
    @include rem(margin-right, 16px);
  }

  .Select__container {
    width: 100%;
  }
  &__select {
    width: 100% !important;
    .Select__icon {
      width: 15px;
      height: 15px;
    }
  }
  .Select__placeholder {
    width: 100% !important;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    @include rem(margin-top, 29px);
    border-top: 1px solid $primary;
    @include rem(padding-top, 20px);
  }
  &__submit {
    @include rem(width, 166px);
    @include rem(height, 39px);
  }
}
