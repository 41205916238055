@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';
@import '../../../styles/lib';

$border-color: #b3bbc1;

.rbc-overlay {
  @include rem(max-height, 400px);
  overflow: auto;
}
.BigCalendar {
  overflow: auto;
  .rbc-time-gutter {
    min-height: 100vh;
    border-right: 0;
    @include rem(width, 125px);
    @include rem(padding-left, 37px);

    background-color: transparent;
    @include tablet {
      font-size: 10px;
      width: 60px;
    }
    @include tablet {
      @include rem(padding-left, 5px);
    }
  }
  .rbc-row-bg {
    border-left: 1px solid $border-color;

    border-right: 1px solid $border-color;
  }

  .rbc-header {
    text-align: left;
    font-weight: normal;
  }

  &__expanded {
    .rbc-month-view {
      .rbc-month-row {
        min-height: 100%;
        display: table;
      }
      .rbc-show-more {
        display: none;
      }
    }
  }
  .rbc-month-view {
    @include rem(padding, 21px 37px 60px);
    max-height: 55vh;
    width: unset;
    border: none;
    overflow: auto;
    .rbc-month-row {
      @include rem(min-height, 390px);
    }
    .Event {
      &__button {
        @include rem(height, 46px);
      }
      &__date {
        display: none !important;
      }
    }
    .rbc-event-content {
      margin: 0 !important;
    }
  }

  .rbc-row-resource {
    border-bottom: none !important;
  }

  .rbc-time-header {
    @include rem(padding-bottom, 21px);
  }
  .rbc-row-content {
    display: flex;
    flex-direction: column;
    height: 100% !important;
    position: relative;
  }

  .rbc-month-header {
    border-top: none !important;
    border-bottom: 1px solid $border-color;
  }

  .rbc-events-container {
    border-color: $border-color;
  }
  .rbc-row-segment {
    @include rem(padding, 0 9px 0 1px);
  }
  .rbc-ellipsis,
  .rbc-event-label,
  .rbc-row-segment .rbc-event-content,
  .rbc-show-more {
    white-space: unset;
    @include rem(margin, 5px 0px);
  }
  .rbc-show-more {
    position: absolute;
    top: 94%;
    background-color: white;
    color: black;
    font-weight: 400;
    @include rem(font-size, 14px);
    @include rem(height, 20px);
    @include rem(line-height, 20px);
    text-align: end;
    @include rem(padding-right, 4px);
    flex-basis: 14%;
    width: 14%;
    &:hover {
      color: $primary;
    }
  }

  .rbc-header {
    border: unset;
    @include rem(padding-bottom, 21px);
    font-size: 1rem;
    @include tablet {
      font-size: 12px;
      line-height: 21px;
      padding-bottom: 5px;
    }
  }

  .rbc-time-view {
    width: 100%;
    border: unset;
    max-height: 60vh;
    overflow: auto;

    @include rem(border-radius, 0 0 70px 190px);
    @include rem(margin-top, 21px);
    .rbc-time-header {
      padding: 0;
    }

    @include tablet {
      max-height: 70vh;
      margin-top: 0px;
      @include rem(border-radius, 0 0 15px 15px);
    }
  }

  .rbc-time-content {
    @include rem(border-radius, 0 0 70px 190px);
    border-top: 0;
    @include rem(padding, 0 35px 35px 0);
    @include tablet {
      @include rem(border-radius, 0 0 15px 15px);
    }
    overflow-y: auto;
  }
  @include tablet {
    ::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
    /* Optional: show position indicator in red */
    ::-webkit-scrollbar-thumb {
      background: white;
    }
  }

  .rbc-time-header {
    @include rem(padding-right, 35px);
  }

  .rbc-time-header-content {
    border: 0;
  }

  .rbc-timeslot-group {
    border-bottom: 0;
  }

  .rbc-day-slot {
    min-height: 100vh;

    &:last-child {
      border-right: 1px solid $border-color;
    }

    .rbc-timeslot-group {
      border-bottom: 1px solid $border-color;
    }
    .rbc-events-container {
      border-top: 1px solid $border-color;
    }
  }

  .rbc-label {
    color: #b3bbc1;
    font-weight: 600;
    line-height: 21px;
  }
  .rbc-current-time-indicator {
    display: none;
  }

  .rbc-today {
    background-color: white;
    // margin-top: 100px;
  }

  .rbc-day-slot {
    border-top: 0;
  }

  .rbc-time-slot {
    @include rem(min-height, 25px);
    @include tablet {
      min-height: 25px;
    }
  }
  .rbc-time-header.rbc-overflowing {
    border-right: 0;
  }

  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-left: 0;
  }

  // .rbc-row-content {
  //   > div:last-child {
  //     // border-bottom: 1px solid $border-color;
  //   }
  // }\

  .rbc-time-view-resources {
    border-right: 0;
    .rbc-row-content {
      border-right: 1px solid $border-color;
    }

    .rbc-time-header-content {
      border-top: 0;
      border-left: 0;
    }

    .rbc-time-header-gutter {
      border-right: 0 !important;
    }

    .rbc-time-gutter {
      border-right: 1px solid $border-color;
    }

    .rbc-time-content {
      > div:last-child {
        border-right: 1px solid $border-color;
      }
    }
  }

  .rbc-timeslot-group {
    @include rem(height, 107px);
  }

  .rbc-time-view .rbc-row {
    min-height: auto;
  }

  .rbc-events-container {
    margin-right: 0;
  }

  .rbc-event-label {
    color: #707070;
  }
}

.rbc-allday-cell {
  display: none;
}
// .calendar--week {
//   .rbc-time-view {
//     @include rem(margin-top, 40px);
//   }
//   .rbc-time-header-content {
//     border-top: 0;
//   }
//   .rbc-time-header-content {
//     border-left: 0;
//   }
//   .rbc-row-bg {
//     border-left: 1px solid $border-color;
//   }
//   .rbc-header {
//     border-color: $border-color;
//     border-left: 0;
//   }
//   .rbc-time-view {
//     border-right: 0;
//   }
//   .rbc-time-content,
//   .rbc-row-content {
//     border-right: 1px solid $border-color;
//   }
// }
.calendar--day {
  .rbc-time-view {
    margin-top: 45px;
  }
  .rbc-time-header {
    > div:nth-child(2) {
      .rbc-allday-cell {
        border-right: 1px solid #ddd;
      }
    }
  }
  .rbc-time-content {
    > div:nth-child(2) {
      border-left: 1px solid #ddd;
    }
  }

  .rbc-time-header-content {
    border-left: 0;
  }

  .rbc-time-view-resources {
    .rbc-time-header {
      .rbc-time-header-content:nth-child(2) {
        .rbc-row-content {
          border-right: 0;
        }
      }
    }
  }
  .rbc-timeslot-group {
    border-left: 0;
  }
  .rbc-time-view-resources {
    .rbc-time-gutter .rbc-time-column {
      .rbc-timeslot-group {
        border-right: 1px solid #ddd;
      }
    }
  }
  .rbc-events-container {
    border-left: 0;
  }
  .rbc-time-view-resources {
    .rbc-events-container {
      border-left: 1px solid #ddd;
    }
  }

  // .rbc-allday-cell {
  //   border-left: 1px solid #ddd;
  // }
  // .rbc-time-view:not(.rbc-time-view-resources) {
  //   .rbc-allday-cell {
  //     border-right: 0 !important;
  //     > div {
  //       border-right: 1px solid #ddd;
  //     }
  //   }
  // }
}
