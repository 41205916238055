@import '../../../styles/lib';

.NavSidebar {
  position: relative;
  height: 100%;
  transition: $transition1;
  z-index: 1;
  @include rem(width, 204px);
  @include rem(padding, 85px 0 0 78.2px);
  @include rem(border-radius, 0 75px 0 0);
  background-color: white;
  @include mobile {
    @include rem(width, 185px);
    @include rem(padding, 85px 0 0 60px);
  }
  height: 100vh;
  &::after {
    content: '';
    position: absolute;
    z-index: -999;
    @include rem(width, 228px);
    @include rem(height, 228px);
    @include rem(right, -226px);
    bottom: 0;
    // right: -228px;
    @include rem(border-bottom-left-radius, 228px);
    box-shadow: -70px 72px 0 70px white;
    @include tablet {
      display: none;
    }
  }

  .icon {
    transition: all 0.5s;
    @include rem(height, 10px);
    @include rem(width, 6px);
  }
  &__closed {
    position: absolute;
    transform: translateX(-14rem);
    transition: $transition1;
    transform-origin: 1rem;
    .NavSidebarButton {
      position: absolute;
      @include rem(right, -80px);
    }
    .icon {
      transition: all 0.5s;
      transform: rotate(180deg);
    }
  }
  &__wrapper {
    position: relative;
    grid-area: sidebar;
    @include rem(width, 204px);
    @include small-desktop {
      position: absolute;
      grid-area: sidebar;
      top: 10%;
      width: 100px;
      z-index: 200;
    }
  }
}
